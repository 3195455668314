body{
  overflow-x: hidden !important;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: '20px 0';
}

.header span{
  font-size: 16px;
  font-weight: bold;
}

.logo {
  color: white;
  font-size: 20px;
  font-weight: bold;
}


/* Full-Screen Banner */
.banner {
  background-image: url('./assets/cover.png'); /* Replace with your banner image */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  position: relative;
}

/* Dark Opacity Overlay */
.banner-overlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
z-index: 1; /* Ensure overlay is above the banner image but below the text */
}

.banner-content {
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 100%;
}

.banner-title {
  font-size: 48px;
  font-weight: bold;
  color: white;
}

.banner-description {
  font-size: 20px;
  color: white;
  margin-top: 10px;
}

.banner-content {
  text-align: left;
  color: white;
  opacity: 0; /* Start invisible */
  z-index: 2; /* Ensure content is above the overlay */
  transform: translateY(20px); /* Start slightly below */
  animation: fadeInUp 0.7s forwards; /* Apply the animation */
}

/* Keyframe Animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.about-us-content-text{
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding-left: 50px;
  opacity: 0; /* Start invisible */
  transform: translateX(-50px); /* Start slightly off-screen to the left */
  animation: textSlideIn 0.7s forwards; /* Apply the animation */
}
.about-us-content-text p{
  line-height: 1.6;
  font-size: 18px;
  
}
.about-us-content-img{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0; /* Start invisible */
  transform: translateX(50px); /* Start slightly off-screen to the right */
  animation: imgSlideIn 0.7s forwards; /* Apply the animation */
}

@keyframes textSlideIn {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes imgSlideIn {
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.footer-item{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

#menu-page span{
  font-size: 16px;
  font-weight: bold;
}

.general-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.general-info-text{
  font-size: 16px;
  padding: 20px;
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.general-info-img{
  border-radius: 0;
  border: 0;
}
.general-info-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;

}