.ant-menu {
  color: #fff; /* Dark blue background */
}

/* Change the text color of menu items */
.ant-menu-item {
  color: #ffffff; /* White text color */
}

/* Change the hover color of menu items */
.ant-menu-item:hover {
  background-color: #EFF6FF !important; /* Blue background on hover */
}

/* Change the selected menu item color */
.ant-menu-item-selected {
  background-color: #fff !important; /* Blue background for the selected item */
  color: #ffffff; /* Ensure text color remains white for selected item */
}