body {
  /* overflow: hidden; */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05); /* Dark transparent layer */
  z-index: 1; /* Ensure it is above the image */
}
